const siteUrl = 'https://www.findlayauto.com/'

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: 'Findlay',
    siteUrl:'https://www.findlayauto.com/',
    site_id: '6201a50613640338d5eb6f1f',
    api_base_url: 'https://api.dealermasters.com:4500',
    lead_url: 'https://leads.dealermasters.com/leads',
    distance_history: 'https://aalnfl2zj7.execute-api.us-east-2.amazonaws.com/Prod', //Distance history API Gateway AWS
    googleAPIkey: 'AIzaSyCddq6ehnmTvcAdFOvG5xEGhn8jZV3eBqY',
    quickgenAPI: 'https://dev.mxsapi.com',
    imageBaseURL: 'https://images.mxssolutions.com', //PROD: 'https://images.mxssolutions.com'
    opensearchAPI: 'https://tehy4uupcb.execute-api.us-east-2.amazonaws.com/opensearch-inventory-search-api/'// DEV: 'https://q0mj4jv9i6.execute-api.us-east-2.amazonaws.com/opensearch-inventory-search-api/'
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: "gatsby-plugin-force-trailing-slashes"
    },
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
      },
    },
    {
      resolve: "gatsby-plugin-emotion"
    },
    {
      resolve: "gatsby-transformer-json"
    },
    {
      resolve: "gatsby-plugin-sass",
      options: {
        cssLoaderOptions: {
          camelCase: false,
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /\.inline\.svg$/
        }
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `src`,
        path: `${__dirname}/src/`
      }
    },
    {
      resolve: 'gatsby-plugin-s3',
      options: {
        bucketName: 'mxs-659ef0e8-dbf3-4a52-8e1d-77a7b901fb7b-prodsite'
      }
    },
    `gatsby-transformer-json`,
    {
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'inventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: ['50846','44670','65539','115311','44665','43574','51665','166084','129109','44850','63641','63641','86282','94029','53355','44664','51353','44847','61733','44666','142897','50869','142901','52134','37946','45127','145524','44667','144655','45128','52132','186209','186208','215191','235918']//126024
      }
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'static/favicon.png'
      }
    },{
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.findlayauto.com/',
        sitemap: 'https://www.findlayauto.com//sitemap-pages.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    }
  ]
}